import React from 'react'
import { Link } from 'gatsby'
import { graphql } from 'gatsby'
import Layout from '../components/layout'
import Pill from '../components/pill'
import PublicationCard from '../components/publicationCard'
import styles from '../styles/member.module.css'
import BackArrow from '../assets/back-arrow.svg'

import WebsiteIcon from '../assets/website.svg'

const ProjectPubs = ({data}) => {
    const awardeePublications = (people) => {
        var pubs = []
    //    console.log("awardee:" + JSON.stringify(people))
        const wPubs =  people?.filter( (p) =>  {
      //      console.log("awardee person:" + JSON.stringify(p))
       //     console.log("awardee person pub count:" + (p.data?.RadXPublication?.length > 0 ) )
            return p.data?.RadXPublication
        } ).map((p) => p.data.RadXPublication )
     //   console.log("filtered pubs:" +JSON.stringify(wPubs))
        console.log("awardee pubs length:" + wPubs?.length)
        if (wPubs?.length > 0) {
    //        console.log("awardee pubs loop:" + wPubs?.length)
            wPubs.forEach((a) => {
    //            console.log("awardee publication:" + JSON.stringify(a))
                for (let i = 0; i < a.length; i++) {
                    pubs.push(a[i]);
                }

            })
        }
   //     console.log(`awardee: ${people.Name}  ${pubs.length}`)
   //     console.log(`awardee pub list:  ${JSON.stringify(pubs)}`)
        return pubs
        // return {
        //   RadXPublication:pubs
        // }
    }
    const { airtableAwardees:project } = data
	  const {   Name:name,  Learn_more:website, pi_organization:institution, category:category,  People:people, contact_pi_name:pis } = project.data

    return (
        <Layout>
            <Link to={`../`}><div className={styles.back}><BackArrow/><span>Back to network</span></div></Link>
            <div className={styles.member}>
                <div className={styles.header}>

                    <h2>{name}</h2>
                    <p>{institution.map((inst, i) => <p key={i} className={styles.role}>{inst.data.Name}</p>)}</p>
                    <p>{pis?.map((p, i) => <p key={i} className={styles.role}>{p?.data?.display_name}</p>)}</p>
                    <div>
                        <Pill label={category}/>

                    </div>

                    <div className={styles.links}>

                        {
                            website && (
                                <a href={website} target='_blank' rel='noreferrer'>
                                    <WebsiteIcon/>
                                    <span>Website</span>
                                </a>
                            )
                        }
                    </div>
                </div>
                {
                  people && (
                        <>
                            <h3>Related publications</h3>
                            <div className={styles.publications}>
                                {
                                    awardeePublications(people).map( (publication, i) => (
                                      !publication.data.HideFromNetwork &&    (
                                        <PublicationCard key={i} title={publication.data.Title} author={publication.data.Author} source={publication.data.Source} date={publication.data.Date}
                                                         abstract={publication.data.Abstract} slug={publication.data.slug}
                                                          />
                                    )
                                    )
                                    )
                                }
                            </div>
                        </>
                    )
                }

            </div>
        </Layout>
    )
}

export const query = graphql`
    query  ($slug: String!)  {
    
        airtableAwardees(data: {Slug: {eq:$slug}}, parent: {children: {}}) {
            id
            data {
                Name
                Slug
                NIH_Project_Reporter
                category
                project_title
                Learn_more
                pi_organization  {
                    ... on  AirtableInstitutions {
                        id
                        data {
                            Name
                        }
                    }
                }
                contact_pi_name  {
                    ... on  AirtablePeople {
                        id
                        data {
                            display_name
                        }
                    }
                }
                grant_number
                People {
                    ... on AirtablePeople {
                        id
                        data {
                            display_name
                            RadXPublication {
                                data {
                                    Abstract
                                    Author
                                    Date
                                    DigestLatest
                                    HideFromNetwork
                                    Journal
                                    Last_Modified
                                    Name
                                    Source
                                    Source_type
                                    Title
                                    Topics
                                    keywords
                                    slug
                                }
                            }
                        }
                        children {
                            id
                        }
                    }
                }
            }
        }
    }


`

export default ProjectPubs
